import dynamic from 'next/dynamic'

const DeliveryIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Delivery'),
)
const StoreIcon = dynamic(
  () => import('@thg-commerce/gravity-icons/src/components/Store'),
)

import { css, mq, spacing, styled, Text } from '@thg-commerce/enterprise-theme'
import {
  Button,
  CustomDropdown,
  Pagination,
  SafeHtml,
  Skeleton,
  visuallyHiddenMixin,
} from '@thg-commerce/gravity-elements'
import { Grid, GridItem } from '@thg-commerce/gravity-system'
import {
  BreakpointArray,
  margin,
  mediaQueryRenderer,
  zIndex,
  ZIndexLevel,
} from '@thg-commerce/gravity-theme'
import { HorizontalAlignment } from '@thg-commerce/gravity-theme/alignments'

export const Container = styled.div<{
  headerHeight: number
  containerSpacing: BreakpointArray<number>
}>`
  max-width: ${(props) => props.theme.site.siteWidth};
  margin: 0 auto;
  margin-bottom: ${spacing(2)};
  padding: ${spacing(3)} ${spacing(2)} 0;
  align-items: center;
  scroll-margin-top: ${(props) => `${props.headerHeight}px`};

  ${(props) =>
    props.containerSpacing &&
    mediaQueryRenderer(
      props.containerSpacing,
      (spacingSize) => `padding: ${spacing(3)} ${spacing(spacingSize)} 0;`,
    )}
`

export const FacetProductWrapper = styled.div`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: flex;
  }
`

export const VerticalFacetsWrapper = styled.div`
  display: none;

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: block;
    flex: 0 0 25%;
    margin-right: ${spacing(4)};
    padding: ${spacing(1)} ${spacing(3)};
  }
`

export const ProductListGrid = styled(Grid)`
  width: 100%;
  height: fit-content;
  margin: 0;
  position: relative;
`
export const ProductListIntroGrid = styled(Grid)<{
  containerSpacing: BreakpointArray<number>
}>`
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  position: relative;

  ${(props) =>
    props.containerSpacing &&
    mediaQueryRenderer(
      props.containerSpacing,
      (spacingSize) => `padding: 0 ${spacing(spacingSize)};`,
    )}
`
export const HideOnMobile = styled.div`
  display: none;
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: inline-block;
  }
`

export const ProductListFooterGrid = styled(GridItem)<{
  containerSpacing: BreakpointArray<number>
}>`
  ${(props) =>
    props.containerSpacing &&
    mediaQueryRenderer(
      props.containerSpacing,
      (spacingSize) => `padding: 0 ${spacing(spacingSize)};`,
    )}

  margin-bottom: ${(props) =>
    spacing(
      props.theme.widget.productList.readMoreSection.marginBottomSpacing,
    )};
`

export const StyledGridItem = styled(GridItem)<{
  marginTop?: number
  centerAlign?: boolean
  removePadding?: boolean
  hideOnMobile?: boolean
  isHorizontalFacetsEnabled?: boolean
  hasReverseColumnLayout?: boolean
  marginBottom?: number
  sticky?: boolean
  zIndex?: number
}>`
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;
      z-index: ${props.zIndex || ZIndexLevel.Base};
      top: ${(props) =>
        `calc(${props.theme.patterns.header.headerLogo.mobile.height} - 1px)`};
      background: white;

      ${mq(props.theme.breakpointUtils.map, 'lg')} {
        top: ${(props) =>
          `calc(${props.theme.patterns.header.headerLogo.desktop.verticalMargin} * 2 +
            ${props.theme.patterns.header.headerLogo.desktop.height} + ${props.theme.patterns.header.navigation.height} - 1px)`};
      }
    `}
  ${(props) =>
    props.removePadding &&
    css`
      padding-left: 0;
      padding-right: 0;
    `}

  ${(props) =>
    props.hideOnMobile &&
    css`
      display: none;
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        display: block;
      }
    `}

  ${(props) =>
    props.isHorizontalFacetsEnabled &&
    css`
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        display: none;
      }
    `}

  ${(props) =>
    props.hasReverseColumnLayout &&
    css`
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        display: flex;
        flex-direction: column-reverse;
      }
    `}

    ${(props) =>
    props.hasReverseColumnLayout &&
    mq(props.theme.breakpointUtils.map, 'md') &&
    `
        display: flex;
        flex-direction: column-reverse;
      `}

  margin: ${(props) => `${spacing(props.marginTop || 0)} 0 0 0`};

  ${(props) => props.centerAlign && 'align-self: center;'}

  ${(props) =>
    props.marginBottom && `margin-bottom: ${spacing(props.marginBottom)}`};
`

export const ProductListGridItem = styled(GridItem)<{
  marginTop?: number
  containerSpacing: BreakpointArray<number>
}>`
  ${(props) =>
    props.containerSpacing &&
    mediaQueryRenderer(
      props.containerSpacing,
      (spacingSize) => `overflow: ${spacingSize === 0 ? 'hidden' : 'unset'};`,
    )}
  margin: ${(props) => `${spacing(props.marginTop || 0)} 0 0 0`};
`

export const DescriptionStyledGridItem = styled(StyledGridItem)`
  margin-bottom: ${(props) =>
    `${spacing(
      props.theme.widget.productList.readMoreSection.marginBottomSpacing,
    )}`};
  ${(props) =>
    props.theme.widget.productList.header.horizontalAlignment ===
      HorizontalAlignment.CENTER && `text-align: center;`};
`
const AlternateResultsPositionStyle = css`
  > p {
    text-align: left;
    width: 100%;
    font-size: 14px;
    padding: ${spacing(1.5)} 0 ${spacing(0.5)} ${spacing(2)};
    ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
      padding: ${spacing(1.5)} 0 ${spacing(0.5)};
    }
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    > p {
      font-size: 18px;
      text-align: center;
      padding: 0;
    }
    margin: ${spacing(3)} 0 ${spacing(2)} 0;
  }
`
export const TotalResultsStyledGridItem = styled(StyledGridItem)<{
  alternateResultsPosition?: boolean
}>`
  ${(props) =>
    props.alternateResultsPosition
      ? AlternateResultsPositionStyle
      : props.theme.widget.productList.header.horizontalAlignment ===
          HorizontalAlignment.CENTER &&
        `text-align: center;
       ${mq(props.theme.breakpointUtils.map, 'md')} {
          position: absolute;
          right: ${spacing(1)};
          top: 0;
          text-align: left;
      }`}
`

export const Title = styled.h1<{ hideBottomMargin?: boolean }>`
  ${(props) =>
    css`
      ${Text(
        props.theme.widget.productList.header.title.textStyle.entry,
        props.theme.widget.productList.header.title.textStyle.style,
      )};
      color: ${props.theme.widget.productList.header.title.textStyle.textColor};
      text-transform: ${props.theme.widget.productList.header.title.textStyle
        .transform};
      text-decoration: ${props.theme.widget.productList.header.title.textStyle
        .textDecoration};
    `};
  ${(props) =>
    props.theme.widget.productList.header.horizontalAlignment ===
      HorizontalAlignment.CENTER && `text-align: center;`}
  ${(props) =>
    props.theme.widget.productList.header.title.marginBottomSpacing &&
    mediaQueryRenderer(
      props.theme.widget.productList.header.title.marginBottomSpacing,
      (spacingSize) =>
        props.hideBottomMargin
          ? `margin-bottom: 0;`
          : `margin-bottom: ${spacing(spacingSize)};`,
    )}
`

export const HiddenTitle = styled.h2`
  ${visuallyHiddenMixin}
`
export const StyledSafeHTML = styled(SafeHtml)`
  h2 {
    ${(props) =>
      Text(
        props.theme.widget.productList.seoDescription.header.textStyle.entry ||
          'medium1',
        props.theme.widget.productList.seoDescription.header.textStyle.style ||
          'alternate',
      )};
  }
  a {
    ${(props) =>
      Text(
        props.theme.widget.productList.seoDescription.anchor.textStyle.entry ||
          'bodyText',
        props.theme.widget.productList.seoDescription.anchor.textStyle.style ||
          'default',
      )};
  }
`

export const ProductListItem = styled.li<{
  oneProductPerRow?: boolean
}>`
  position: relative;
  margin: 0 0 ${(props) => spacing(props.oneProductPerRow ? 4 : 3)};
  padding: 0
    ${(props) =>
      props.oneProductPerRow
        ? '0'
        : spacing(props.theme.widget.productList.gridGap / 2)};
  ${spacing(4)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    padding-right: ${(props) =>
      spacing(props.theme.widget.productList.gridGap / 2)};
    padding-left: ${(props) =>
      spacing(props.theme.widget.productList.gridGap / 2)};
  }
`

export const QubitListItem = styled(ProductListItem)<{
  colSpanOnDesktop?: number
  colSpanOnMobile?: number
}>`
  ${(props) =>
    props.colSpanOnMobile
      ? css`
          display: block;
          grid-column: 1 / ${props.colSpanOnMobile};
          margin: ${spacing(2)} 0;
        `
      : 'display: none;'}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    ${(props) =>
      props.colSpanOnDesktop
        ? css`
            display: block;
            grid-column: 1 / ${props.colSpanOnDesktop};
            margin: ${spacing(4)} 0;
          `
        : 'display: none;'}
`

export const WishlistButtonWrapper = styled.div`
  position: absolute;
  top: ${spacing(1)};
  right: ${spacing(3)};
`

export const StyledDropdown = styled(CustomDropdown)`
  margin-right: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-right: ${spacing(2)};
  }

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    margin-right: 0;
  }
`

const RefineButtonText = (props) =>
  props.theme.widget.productList.filterButton.textStyle &&
  css`
    ${Text(
      props.theme.widget.productList.filterButton.textStyle.entry,
      props.theme.widget.productList.filterButton.textStyle.style,
    )};
    color: ${props.theme.widget.productList.filterButton.textStyle.textColor};
    text-decoration: ${props.theme.widget.productList.filterButton.textStyle
      .textDecoration};
    text-transform: ${props.theme.widget.productList.filterButton.textStyle
      .transform};
  `

export const RefineButton = styled(Button)`
  display: block;
  height: 100%;
  margin-left: ${spacing(1)};
  ${(props) =>
    props.theme.widget.productList.filterButton.border &&
    css`
      border-width: ${props.theme.widget.productList.filterButton.border.width};
      border-radius: ${props.theme.widget.productList.filterButton.border
        .radius};
    `}

  ${(props) => margin(props.theme.widget.productList.filterButton.margin)}

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: none;
  }

  &:hover,
  &:focus,
  &:focus-within,
  &:active {
    display: block;

    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      display: none;
    }

    ${(props) =>
      props.theme.widget.productList.filterButton.focus?.backgroundColor &&
      `background-color: ${props.theme.widget.productList.filterButton.focus.backgroundColor};`}

    ${(props) =>
      props.theme.widget.productList.filterButton.focus?.border &&
      css`
        border-width: ${props.theme.widget.productList.filterButton.focus.border
          .width};
        border-style: ${props.theme.widget.productList.filterButton.focus.border
          .style};
        border-color: ${props.theme.widget.productList.filterButton.focus.border
          .color};
      `}

    ${(props) =>
      props.theme.widget.productList.filterButton.border &&
      `border-radius: ${props.theme.widget.productList.filterButton.border.radius};`}
    ${RefineButtonText}
  }

  button {
    align-items: center;
    padding: ${spacing(1)};
    :hover,
    :active {
      padding: ${spacing(1)};

      ${(props) =>
        props.theme.widget.productList.filterButton.active?.border &&
        css`
          border-width: ${props.theme.widget.productList.filterButton.active
            .border.width};
          border-style: ${props.theme.widget.productList.filterButton.active
            .border.style};
          border-color: ${props.theme.widget.productList.filterButton.active
            .border.color};
        `}

      ${RefineButtonText}
    }
    :focus {
      padding: ${spacing(1)};
      ${(props) =>
        props.theme.widget.productList.filterButton.border &&
        `border-radius: ${props.theme.widget.productList.filterButton.border.radius};`}

      ${(props) =>
        props.theme.widget.productList.filterButton.focus?.backgroundColor &&
        `background-color: ${props.theme.widget.productList.filterButton.focus.backgroundColor};`}

      ${RefineButtonText}
    }

    ${(props) =>
      props.theme.widget.productList.filterButton.border &&
      `border-radius: ${props.theme.widget.productList.filterButton.border.radius};`}
    ${RefineButtonText}
  }
`

export const RefineIconTextWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

export const RefineText = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: ${spacing(0.5)};
`

export const StyledPagination = styled(Pagination)`
  nav {
    justify-content: center;
    width: 100%;
    ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
      justify-content: flex-end;
    }
  }

  nav > button {
    :first-child {
      padding: 0;
      margin-left: 0;
      ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
        margin-right: ${spacing(2)};
      }
      svg {
        margin-right: ${spacing(1)};
      }
    }
    :last-child {
      padding: 0;
      margin-right: 0;
      ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
        margin-left: ${spacing(2)};
      }
      svg {
        margin-left: ${spacing(1)};
      }
    }
  }
`
export const TotalResults = styled.p<{
  alternateResultsPosition?: boolean
  textAlign?: string
}>`
  ${(props) =>
    Text(
      props.theme.widget.productList.totalResults.textStyle.entry,
      props.theme.widget.productList.totalResults.textStyle.style,
    )}
  ${(props) =>
    props.alternateResultsPosition
      ? `width:fit-content`
      : `margin-bottom: ${spacing(2)}`};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    text-align: ${(props) => props.textAlign || 'right'};
    margin-bottom: 0;
  }
`

export const TotalResultsSkeleton = styled(Skeleton)`
  max-height: ${spacing(2.5)};
  width: 30%;
  margin-bottom: ${spacing(2)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    width: 100%;
    margin-bottom: 0;
  }
`

export const PaginationSkeleton = styled(Skeleton)<{ hideOnMobile?: boolean }>`
  ${(props) =>
    props.hideOnMobile &&
    css`
    display: none;
    ${mq(props.theme.breakpointUtils.map, 'md')} {
      display: block;
    }}`}
  max-height: ${spacing(6)};
  margin-left: ${spacing(1)};

  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    margin-left: ${spacing(2)};
  }
`
export const TitleSkeleton = styled(Skeleton)`
  max-height: ${spacing(4)};
  width: 100%;
  ${(props) => mq(props.theme.breakpointUtils.map, 'sm')} {
    max-height: ${spacing(6)};
    width: 40%;
  }
`
export const ReadMoreSkeleton = styled(Skeleton)`
  width: 100%;
  max-height: ${spacing(8)};
`
export const AccessibilityButton = styled(Button)`
  border: none;
  button:focus {
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};
  }

  button:hover {
    padding-left: ${spacing(1)};
    padding-right: ${spacing(1)};
  }

  button:not(:focus) {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    overflow: hidden;
    clip: rect(0 0 0 0);
  }
`
export const RefinePaginationWrapper = styled.div<{
  isHorizontalFacetsEnabled?: boolean
}>`
  ${(props) => mq(props.theme.breakpointUtils.map, 'md')} {
    display: ${(props) => (props.isHorizontalFacetsEnabled ? 'flex' : 'auto')};
    flex-direction: column-reverse;
  }
`

const reusableProductBlockIconProperties = css<{ available: boolean }>`
  fill: ${(props) =>
    props.available
      ? props.theme.colors.palette.brand.base
      : props.theme.colors.palette.greys.grey};
`
export const StyledDeliveryIcon = styled(DeliveryIcon)<{
  available: boolean
}>`
  path {
    ${reusableProductBlockIconProperties}
  }
`

export const StyledStoreIcon = styled(StoreIcon)<{
  available: boolean
}>`
  ${reusableProductBlockIconProperties}
`
export const StyledDeliveryText = styled.p<{
  available: boolean
}>`
  ${Text('small', 'default')};
  margin-left: ${spacing(1)};
  color: ${(props) =>
    props.available
      ? props.theme.colors.palette.greys.darker
      : props.theme.colors.palette.greys.grey};
`

export const StickyFilterGrid = styled(Grid)<{
  containerSpacing: BreakpointArray<number>
  sticky?: boolean
  requestIsMobileApp?: boolean
  zIndex?: ZIndexLevel
}>`
  ${(props) =>
    props.sticky &&
    css`
      position: sticky;

      top: ${
        (props.requestIsMobileApp && '0px') ||
        props.theme.patterns.header.headerLogo.mobile.headerHeight
      };
      ${zIndex(ZIndexLevel.Base)};
      background: ${props.theme.colors.palette.greys.white};
      border-bottom: ${props.theme.patterns.header.navigation.borderBottom};
      ${mq(props.theme.breakpointUtils.map, 'md')} {
        border-bottom: 0;
        position: relative;
        top: 0;
      }}
    `}

  ${(props) =>
    props.containerSpacing &&
    mediaQueryRenderer(
      props.containerSpacing,
      (spacingSize) => `padding: ${spacing(1)} ${spacing(spacingSize)};`,
    )}
  ${(props) => props.zIndex && zIndex(props.zIndex)}
`
